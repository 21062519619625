<template>
  <div>
    <v-stepper class="mt-n5" v-model="e1">
      <v-progress-linear
        color="primary"
        buffer-value="0"
        :value="(e1 / 7) * 100"
      ></v-progress-linear>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="e1 > 1">
          Need
          <small>Need Identification</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="e1 > 2">
          Pre-approval
          <small>Obtain Pre-approval</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="e1 > 3">
          Supplier Selection
          <small>Select pre-qualified suppliers</small>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="e1 > 4">
          Approval
          <small>as per approval authority limits</small>
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="5" :complete="e1 > 5">
          Contract
          <small>Initiation</small>
        </v-stepper-step>

        <v-stepper-step step="6" :complete="e1 > 6">
          Purchase
          <small>Requisition</small>
        </v-stepper-step>

        <v-stepper-step step="7" :complete="e1 > 7">
          Purchase
          <small>Order</small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-divider class="my-2"></v-divider>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterText"
    >
      <template v-slot:top>
        <v-toolbar flat class="pt-2">
          <v-row>
            <v-col cols="8">
              <span class="text-capitaliz primary--text">{{ name }}</span>
            </v-col>
            <v-col cols="4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="goToSupplierSelection"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-format-list-bulleted-square</v-icon>
                  </v-btn>
                </template>
                <span>Back to supplier categories</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="text-capitalize ml-1 float-right"
                    :loading="loading"
                    @click="newSupplierModal"
                    elevation="0"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-account-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>Add new supplier</span>
              </v-tooltip>
              <v-text-field
                label="search..."
                v-model="search"
                append-icon="mdi-magnify"
                dense
                width="54"
                color="primary"
                class="float-right"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.company_logo_url`]="{ item }">
        <v-img
          max-height="64"
          max-width="64"
          :src="item.company_logo_url"
          class="my-3 mr-auto"
          rounded
          outlined
          :lazy-src="item.company_logo_url"
        ></v-img>
      </template>
      <template v-slot:[`item.company`]="{ item }">
        {{ getCompanyName(item.company) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row align="center" justify="space-around">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on" class="primary">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="my-n2" @click="selectSupplier(item)">
                <span class="info--text">
                  <v-icon color="info">mdi-account-arrow-right-outline</v-icon>
                  RFQ
                </span>
              </v-list-item>
              <v-list-item class="my-n2" @click="selectSupplier(item)">
                <span class="info--text">
                  <v-icon color="info">mdi-account-arrow-right-outline</v-icon>
                  RFP
                </span>
              </v-list-item>
              <v-list-item class="my-n2" @click="selectSupplier(item)">
                <span class="success--text">
                  <v-icon color="primary"
                    >mdi-account-arrow-right-outline</v-icon
                  >
                  Select
                </span>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </template>

      <template v-slot:[`item.bidders_count`]="{ item }">
        <v-btn
          class="mx-auto"
          :elevation="0"
          dark
          small
          rounded
          color="primary"
        >
          <span class="font-weight-bold button"> {{ item.bidders_count }}</span>
        </v-btn>
      </template>

      <template v-slot:[`item.clarifications_count`]="{ item }">
        <v-btn
          class="mx-auto"
          :elevation="0"
          dark
          small
          rounded
          color="warning"
          @click="clarificationPreview(item)"
        >
          <span class="font-weight-bold button">
            {{
              item.clarifications_answered + "/" + item.clarifications_count
            }}</span
          >
        </v-btn>
      </template>
    </v-data-table>

    <!-- Supplier modal -->
    <div class="text-center">
      <v-dialog v-model="supplier_modal" max-width="550px">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>
              <span class="headline error--text text--darken-1"
                >Suppliers invitation</span
              >
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                depressed
                fab
                x-small
                @click="close"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="my-n2">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Title"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Name
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="my-n2">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Email address
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="my-n2">
                    <v-text-field
                      v-model="editedItem.phone"
                      label="Email"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Phone<v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="saveWorkflow"
              >
                Submit <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialog_select" max-width="550px">
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>
            <span class="headline primary--text">Supplier selection</span>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              class="text-capitalize mx-1"
              dark
              fab
              x-small
              elevation="0"
              @click="dialog_select=false"
            >
            <v-icon small>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="my-n2"></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" class="my-n2">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Reason/Justification *"
                    :rules="rules.required"
                    color="grey"
                    dense
                    outlined
                    class="mb-n3"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="my-n5">
                  <v-textarea
                    v-model="editedItem.description"
                    outlined
                    name="input-7-4"
                    label="Details"
                    color="grey"
                    dense
                    :rules="rules.required"
                    class="mb-n3"
                  >
                    <template v-slot:label>
                      <span class="input__label"
                        >Document Description
                        <v-icon small color="error" class="mt-n2"
                          >mdi-star-outline</v-icon
                        ></span
                      >
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="my-n1">
                  <v-file-input
                    v-model="editedItem.attachment_file"
                    color="grey"
                    append-icon="mdi-attachment"
                    prepend-icon=""
                    label="Support Document"
                    outlined
                    dense
                    @change="handleFileUpload"
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize mx-1"
              :loading="loading"
              @click="continueToApproval"
            >
              Submit <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TenderService from "../../services/tender.service";
import SupplierCategoryService from "../../services/supplier_category.service";
import PrequalificationService from "../../services/prequalification.service";
import moment from "moment";
import User from "../../models/user";

export default {
  components: {
    // UpdateProfile,
  },
  props: {
    id: {},
    name: {},
  },
  data: (vm) => ({
    e1: 3,
    name: "MarketPlace",
    dialog_select: false,
    action_buttons: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    items: [],
    supplier_modal: false,
    supplier_categories: [],
    required_documents: [],
    technical_documents: [],
    financial_documents: [],
    editedIndex: -1,
    loading: true,
    closingDateWatcher: new Date().toISOString().substr(0, 10),
    menu_closing_date: false,
    clarificationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_clarification_date: false,
    evaluationDateWatcher: new Date().toISOString().substr(0, 10),
    menu_evaluation_date: false,
    approvalDateWatcher: new Date().toISOString().substr(0, 10),
    menu_approval_date: false,

    editedItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },
    defaultItem: {
      tender_no:
        "ABC/" +
        Math.floor(Math.random() * 9999 + 1000) +
        "/" +
        moment().format("yyyy"),
      title: "",
      description: "",
      category: "",
      budget: "",
      application_fee: "",
      application_steps: "",
      closing_date: vm.formatDate(new Date().toISOString().substr(0, 10)),
      clarification_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      evaluation_end_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      tender_approval_date: vm.formatDate(
        new Date().toISOString().substr(0, 10)
      ),
      status: "",
      required_documents: [],
      technical_documents: [],
      financial_documents: [],
      required_document_ids: [],
      technical_document_ids: [],
      financial_document_ids: [],
    },

    tender_steps: [
      {
        id: 1,
        name: "One step",
      },
      {
        id: 2,
        name: "Two step",
      },
      {
        id: 3,
        name: "Three step",
      },
    ],

    tender_status: [
      {
        id: 1,
        name: "Draft",
      },
      {
        id: 2,
        name: "Open",
      },
      {
        id: 3,
        name: "Closed",
      },
    ],

    page: "Tenders",
    selected: [0],
    search: "",
    files: "",
    docURI:
      "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
    tenderCategories: [],
    user: new User("", ""),
    remember: 0,
    timeout: 2000,
    dialog: false,
    countries: ["Kenya", "Uganda", "Tanzania", "Rwanda"],
    owners: [1, 2, 3, 4],
    valid: true,
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
    },

    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Logo",
        value: "company_logo_url",
      },
      {
        text: "Company Name",
        value: "company.company_name",
      },
      {
        text: "Applicant Name",
        value: "name",
      },
      {
        text: "Contact Phone",
        value: "phone",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    computedClosingDate() {
      return this.formatDate(this.editedItem.closing_date);
    },
    computedClarificationDate() {
      return this.formatDate(this.editedItem.clarification_end_date);
    },
    computedEvaluationDate() {
      return this.formatDate(this.editedItem.evaluation_end_date);
    },
    computedApprovalDate() {
      return this.formatDate(this.editedItem.tender_approval_date);
    },
    // required docs
    likesAllRequiredDocument() {
      return (
        this.editedItem.required_documents.length ===
        this.required_documents.length
      );
    },
    likesSomerequired_documents() {
      return (
        this.editedItem.required_documents.length > 0 &&
        !this.likesAllRequiredDocument
      );
    },
    iconRequired() {
      if (this.likesAllRequiredDocument) return "mdi-close-box";
      if (this.likesSomerequired_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // technical docs
    likesAllTechnicalDocument() {
      return (
        this.editedItem.technical_documents.length ===
        this.technical_documents.length
      );
    },
    likesSometechnical_documents() {
      return (
        this.editedItem.technical_documents.length > 0 &&
        !this.likesAllTechnicalDocument
      );
    },
    iconTechnical() {
      if (this.likesAllTechnicalDocument) return "mdi-close-box";
      if (this.likesSometechnical_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    // financial docs
    likesAllFinancialDocument() {
      return (
        this.editedItem.financial_documents.length ===
        this.financial_documents.length
      );
    },
    likesSomefinancial_documents() {
      return (
        this.editedItem.financial_documents.length > 0 &&
        !this.likesAllFinancialDocument
      );
    },
    iconFinancial() {
      if (this.likesAllFinancialDocument) return "mdi-close-box";
      if (this.likesSomefinancial_documents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    closingDateWatcher() {
      this.editedItem.closing_date = this.formatDate(this.closingDateWatcher);
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },

  created() {
    this.getAllSuppliers();
    this.getAllCategories();
    this.getCategories();
    this.getAllRequiredDocuments();
    this.getAllTechnicalDocuments();
    this.getAllFinancialDocuments();
  },

  methods: {
    newSupplierModal() {
      this.supplier_modal = true;
    },
    goToSupplierSelection() {
      this.$router.push("/supplier-selection");
    },
    goToApprovals() {
      this.$router.push("/authority-approvals");
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    selectSupplier(item) {
      var sendConfirm = confirm("Confirm supplier selection?");
      if (sendConfirm) {
        localStorage.setItem("supplier", JSON.stringify(item));
        this.dialog_select = true;
      }
    },
    continueToApproval() {
    // save documents and reasons

      this.goToApprovals();
    },
    sendToMkataba(item) {
      this.loading = true;
      item.approval_name = item.title;
      item.applicant_name = this.user.name;
      const index = this.items.indexOf(item);
      var sendConfirm = confirm("Confirm sending supplier to Mkataba parties?");
      if (sendConfirm) {
        item.full_name = item.company.company_name;
        item.country = "KE";
        item.kra_pin = item.company.kra_pin
          ? item.company.kra_pin
          : "KRA" + this.getRandomInt(10000, 99999);
        item.full_name = item.company.company_name;
        item.vat_no = item.company.vat_no
          ? item.company.vat_no
          : "VAT" + this.getRandomInt(10000, 99999);
        item.city_town = "Nairobi" + this.getRandomInt(10000, 99999);
        item.primary_email = item.email;
        item.primary_location = "Nairobi" + this.getRandomInt(10000, 99999);
        item.first_name = item.company.company_name;
        item.last_name = item.name;

        PrequalificationService.sendToMkataba(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
              this.getAllSuppliers();
              this.getAllCategories();
              this.getCategories();
              this.loading = false;
            } else {
              this.loading = false;
              this.$store.dispatch("alert/error", "Error sending supplier");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
            this.loading = false;
          }
        );
      }
    },

    setDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.reverseFormatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.reverseFormatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.reverseFormatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.reverseFormatDate(
        this.editedItem.tender_approval_date
      );
    },
    revertDBDateFormat() {
      // Set date formt
      this.editedItem.closing_date = this.formatDate(
        this.editedItem.closing_date
      );
      this.editedItem.clarification_end_date = this.formatDate(
        this.editedItem.clarification_end_date
      );
      this.editedItem.evaluation_end_date = this.formatDate(
        this.editedItem.evaluation_end_date
      );
      this.editedItem.tender_approval_date = this.formatDate(
        this.editedItem.tender_approval_date
      );
    },
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    // required docs
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.editedItem.required_documents = [];
        } else {
          this.editedItem.required_documents = this.required_documents.slice();
        }
      });
    },
    getAllRequiredDocuments() {
      return TenderService.getAllRequiredDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.required_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // technical docs
    toggleTechnical() {
      this.$nextTick(() => {
        if (this.likesAllTechnicalDocument) {
          this.editedItem.technical_documents = [];
        } else {
          this.editedItem.technical_documents =
            this.technical_documents.slice();
        }
      });
    },
    getAllTechnicalDocuments() {
      return TenderService.getAllTechnicalDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.technical_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    // financial docs
    toggleFinancial() {
      this.$nextTick(() => {
        if (this.likesAllFinancialDocument) {
          this.editedItem.financial_documents = [];
        } else {
          this.editedItem.financial_documents =
            this.financial_documents.slice();
        }
      });
    },
    getAllFinancialDocuments() {
      return TenderService.getAllFinancialDocuments().then(
        (response) => {
          if (response.status == 200) {
            this.financial_documents = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      console.log(`${year}-${month}-${day}`);
      return `${year}-${month}-${day}`;
    },

    getCompanyName(company) {
      return company.company_name;
    },
    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },
    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },
    getCategories() {
      return TenderService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.tenderCategories = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    getAllSuppliers() {
      return PrequalificationService.indexByCategory(this.id).then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllCategories() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.supplier_categories = response.data.data;
            this.loading = false;
          } else {
            this.supplier_categories = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // Format date
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser && this.items.splice(index, 1)) {
        TenderService.deleteTender(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      }
    },

    close() {
      this.dialog = false;
      this.supplier_modal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.setDBDateFormat();
        // edit
        if (this.editedIndex > -1) {
          // convert back to db date
          Object.assign(this.items[this.editedIndex], this.editedItem);
          if (typeof this.editedItem.category != "number") {
            this.editedItem.category = this.editedItem.category.id;
          }
          if (typeof this.editedItem.status != "number") {
            this.editedItem.status = this.editedItem.status.id;
          }
          // check docs
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents =
                this.editedItem.required_document_ids;
            }
            if (this.editedItem.technical_documents[0].id) {
              this.editedItem.technical_documents =
                this.editedItem.technical_document_ids;
            }
            if (this.editedItem.financial_documents[0].id) {
              this.editedItem.financial_documents =
                this.editedItem.financial_document_ids;
            }
          } catch (error) {
            console.log(error);
          }

          TenderService.updateTender(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.getAllSuppliers();
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Invalid data, please check the form try again!"
                  // response.response.statusText
                );
                this.revertDBDateFormat();
                this.getAllSuppliers();
                this.loading = false;
              }
            },
            (error) => {
              console.log(error);
              this.revertDBDateFormat();
              this.getAllSuppliers();
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              }
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        } else {
          // create
          TenderService.storeTender(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Tender created successfully"
                );
                this.getAllSuppliers();
                this.loading = false;
                this.close();
              } else {
                this.revertDBDateFormat();
                this.$store.dispatch(
                  "alert/error",
                  response.response.statusText || "Internal error!"
                );
                this.loading = false;
              }
            },
            (error) => {
              this.revertDBDateFormat();
              this.loading = false;
              console.log(error);
              if (error.response.status == 422) {
                this.$store.dispatch("alert/error", error);
              }
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>
