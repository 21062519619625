// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class PrequalificationService {

    index() {
        return axios.get(API_URL + 'preq-applications', { headers: authHeader() })
    }
    indexByCategory(id) {
        return axios.get(API_URL + 'preq-applications-by-category/' + id, { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'preq-applications/' + id, { headers: authHeader() })
    }
    store(data) {
        return axios.post(API_URL + 'preq-applications', data, { headers: authHeader() })
    }
    update(data) {
        return axios.put(API_URL + 'preq-applications/' + data.id, data, { headers: authHeader() })
    }
    delete(data) {
        return axios.delete(API_URL + 'preq-applications/' + data.id, { headers: authHeader() })
    }
    getSelectedCategories(user) {
        return axios.get(API_URL + 'preq-applicant-categories/' + user, { headers: authHeader() })
    }
    addCategories(data) {
        return axios.post(API_URL + 'preq-add-category', data, { headers: authHeader() })
    }
    sendToMkataba(data) {
        return axios.post(API_URL + 'send-supplier-to-mkataba', data, { headers: authHeader() })
    }


}

export default new PrequalificationService()